import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../components/Image"
import Form from "../components/Form"

const HeroCustom = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "meohlive-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="section" id="form">
      <div className="container">
        <div className="columns">
          <div className="column is-one-fifth px-6">
            <Image data={data} className="max-width-250 min-width-150" />
          </div>
          <div className="column has-text-right is-flex is-justify-content-flex-end">
            {/* <a className="is-align-self-center has-text-primary">
              Faire du sport en live depuis chez moi >
            </a> */}
          </div>
        </div>
        <div className="columns mt-6 is-desktop">
          <div className="column is-two-thirds-desktop is-flex is-flex-direction-column is-justify-content-center px-6 mb-6">
            <h1 className="title is-size-1 has-text-weight-normal">
              Devenez <span className="has-text-primary">meoh-coach</span>
              <br />
              Augmentez vos revenus
              <br />
              tout en maîtrisant
              <br />
              votre emploi du temps
            </h1>
            <h2 className="subtitle is-size-3 has-text-weight-light mt-5">
              Meoh-live est la première plateforme permettant aux coachs
              sportifs de monétiser leurs séances en direct, d’augmenter et
              de fidéliser leur réseau de sportifs.
            </h2>
          </div>
          <div className="column px-6">
            <div className="card has-boxshadow">
              <Form
                url="https://form.typeform.com/to/naAHRG0B?typeform-medium=embed-snippet"
                transparency="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroCustom
